import { NavLink } from 'react-router-dom';
import './customStyles.css'
import './Header.css'
export default function Header() {
  const topStyle = {
    fontFamily: "'Franklin Gothic Medium', 'Arial Narrow', 'Arial', 'sans-serif'", 
    fontSize: "12px",
    backgroundColor:'black'
  }

  const navPaths = [
    { path:'/',name:'Services'},
    { path:'/aboutus', name:"About Us"}
  ]

  return (
    <>
      <div className="text-center text-white" style={topStyle}>
        Email: rajinfosolutions.tech@gmail.com | Phone: +91 8520944933
      </div>

      <header className=" d-flex bg-theme" >
        <div className="">
          <img src="/logos/ris_logo_title.png" alt="ris_logo_title.png" className='d-block rounded' width={'250px'}/>
        </div>
        <div className='ms-auto py-2'>
          <ul className="nav">
            {navPaths.map((item)=>{
              return  <li class="nav-item">
                        <NavLink className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link text-white'}
                        to={item.path}> {item.name} </NavLink>
                      </li>
            })}
          </ul>
        </div>
      </header>
    </>
  );
}
