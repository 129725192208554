import './Alumni.css'
export default function Alumni(){
    const logos = ["/logos/logo1.png" ,"/logos/logo2.png" ,"/logos/logo3.png" ,"/logos/logo4.png" ,
    "/logos/logo5.png" ,"/logos/logo6.png" ,"/logos/logo7.png" ,"/logos/logo8.png" ,
    "/logos/logo9.png" ,"/logos/logo10.png","/logos/logo11.png","/logos/logo12.png"]
    
    return <>
        <div class="container-fluid text-center p-2 rounded-large mt-5 mb-5" >
            <p class="fs-2 fw-bold">OUR STUDENTS IN</p>
            <p className='text-center small'> 
            We are proud to showcase here the list of company names where our trianed 
            students have worked or currently working.</p>
            <div class="marquee border border-danger mt-4">
                <div class="marquee--inner">
                    <span>
                        {logos.map((logo)=>{
                            return <img class="marquee-image" src={logo} alt={logo} />
                            })
                        }
                    </span>
                    <span>
                        {logos.map((logo)=>{
                            return <img class="marquee-image" src={logo} alt={logo} />
                            })
                        }
                    </span>
                </div>
            </div>
        </div>
    </>
}