import FrontCard from './FrontCard';

export default function Services() {
  const bgStyle = {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundImage:  " url('/bgs/blur_bg_1.jpg')",
    color: "white"
}

  return (
    <>
      <div className="container-fluid p-5" style={bgStyle}>
      <p class="fs-2 text-center text-white fw-bold">OUR SERVICES</p>

        <div className="row mt-4 px-0 px-lg-5">
          <div className="col-md-4 col-sm-6 p-1">
            <FrontCard
              title={"Trainings"}
              icon={"bi-people-fill"}
              iconStyle={{ color: "rgb(184, 0, 168)" }}
              description={
                "Our training gives individual a better understanding of their responsibilities and the knowledge and skills they need to do that job. This will improve their confidence which can positively impact performance."
              }
            />
          </div>

          <div className="col-md-4 col-sm-6 p-1">
            <FrontCard
              title={"Placement Assistance"}
              icon={"bi-hand-thumbs-up-fill"}
              iconStyle={{ color: "rgb(5, 55, 100)" }}
              description={
                "The Placement Assistance is assured to the students so that they don't need to worry about their future and our previous batch students have entered into the fortune 100 companies."
              }
            />
          </div>

          <div className="col-md-4 col-sm-6 p-1">
            <FrontCard
              title={"Software Projects"}
              icon={"bi-database-fill"}
              iconStyle={{ color: "crimson" }}
              description={
                "We focus on providing digital services to help enable our customers to succeed in their organizational needs.Together we go forward, step-by-step, to achieve a positive agreed-upon outcome."
              }
            />
          </div>

          <div className="col-md-4 col-sm-6 p-1">
            <FrontCard
              title={"Web Hosting"}
              icon={"bi-pc-display-horizontal"}
              iconStyle={{ color: "rgb(0, 185, 0)" }}
              description={
                "Let us help you create and refresh your Websites and Applications. We manage all from start to go-live process with your requirements statisfied. You are in good hands!"
              }
            />
          </div>

          <div className="col-md-4 col-sm-6 p-1">
            <FrontCard
              title={"Digital marketing"}
              icon={"bi-graph-up-arrow"}
              iconStyle={{ color: "chocolate" }}
              description={
                "We help you Reach a larger audience with better social media marketing and promotions. Get your company in the effectively well promoted."
              }
            />
          </div>

          <div className="col-md-4 col-sm-6 p-1">
            <FrontCard
              title={"Tech Support"}
              icon={"bi-gear-fill"}
              iconStyle={{ color: "#00b7ff" }}
              description={
                "Get Support from us for your Organisation needs with IT infrastructure like AMC, HMC, Network Cabling and many more. Just give us a call..!"
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
