export default function PotraitCard(props){
    const bgStyle = {
        backgroundColor: "var("+ props.bg + ")",
        color: "var("+ props.color + ")"
    }
    return <>
        <div className="card shadow rounded-large" style={bgStyle}>
            <img src={props.src} alt={props.name} className="rounded-top" />
            <div class="d-block text-center">
                  <span><strong>{props.name}</strong></span> <br/>
                  <span class="small">{props.designation}</span>
            </div>
        </div>
    </>
}