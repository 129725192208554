import './customStyles.css'
export default function FrontCard(props) {
    return (
        <div className='card shadow bg-white p-3 text-center h-100 hover-zoom hover-fly'>
            <div className="">
                <i className={'bi ' + props.icon +' display-3 mb-3'} style={props.iconStyle}></i>
                <p className='mb-4 fs-4'>{props.title}</p>
                <p className='small text-secondary' style={{fontSize:"12px"}}>{props.description}</p>
            </div>
        </div>
       
    )
}