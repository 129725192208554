import FrontCard2 from "./FrontCard2";
import "./customStyles.css";
export default function Testimonials() {
  return (
    <>
      <div className="container-fluid p-3 mt-4">
        <p className="fs-2 text-center fw-bold text-success"> Why choose us ?</p>
        <p className="text-center small p-3">
          At Raj Info Solutions we treat our employees and customers like
          family. After all, our business is built upon, and our reputation is
          spread by, our people - so it's important to us to keep them
          satisfied. We offer challenging projects that meet your qualifications
          and interests, and career opportunities including promotions within
          the organization.
        </p>

        <div className="row mt-5 mb-5">
          <div className="col-sm-12 col-md-4 p-1">
            <FrontCard2 title="SVH Eng. College">
                Raj Info Solutions has done an excellent job in enhancing and
                designing Network and configuring it to easy access even through
                remote. The quality provided is at affordable cost and we
                recommend their services to the user community.
            </FrontCard2>
          </div>
          <div className="col-sm-12 col-md-4 p-1">
          <FrontCard2 title="Hastin Computers">
                We have used Techno Towers services offering related to
                software solutions and application development since 2017. We
                are pleased with their offerings. We do recommend this team to
                anyone as a preferred company because of their competent and
                technical personnel.
            </FrontCard2>
          </div>
          <div className="col-sm-12 col-md-4 p-1">
          <FrontCard2 title="Sri Balaji Vidhyalayam">
                Raj Info Solutions formerly Techno Towers has been proving that the Education
                is simple to learn and teach. They've been tutoring computer courses irrespective
                of age, caste, and education backgorund and being available for whoever needed help
            </FrontCard2>
          </div>
        </div>
      </div>
    </>
  );
}
