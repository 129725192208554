import Carousel from "../components/Carousel";
import Services from "../components/services";
import Testimonials from "../components/Testimonials";
import Stats from "../components/Stats";
import Alumni from "../components/Alumni";

export default function Home(){
    return <>
      <Carousel />
      <Services />
      <Testimonials />
      <Stats />
      <Alumni />
    </>
}