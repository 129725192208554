import './customStyles.css'
export default function Footer() {
    const bottomStyle = {
        fontFamily: "'Franklin Gothic Medium', 'Arial Narrow', 'Arial', 'sans-serif'", 
        fontSize: "12px",
        backgroundColor:'black'
      }

    return (
        <>
        <footer className='container-fluid bg-theme text-white'>
            <div className='row p-5 px-lg-5'>
                <div className='col mb-3'>
                    <div className='row'>
                        <div className='fw-bold text-center mb-3 fs-3'>
                            CONTACT US
                        </div>
                        <div className='col'>
                            <div className='text-center p-3'>
                            <i class="bi bi-geo-alt-fill"> </i>Machavaram, Machilipatnam, <br/>
                                AP-521002, India
                            </div>
                        </div>
                        <div className='col'>
                            <div className='text-center p-3'>
                                <i className="bi bi-telephone-fill"> </i>+91 8520944933 <br />
                                <i className="bi bi-envelope-fill"> </i> rajinfosolutions.tech@gmail.com

                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 mb-3'>

                    <iframe title="location map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d676.559098660035!2d81.142161439951!3d16.20144777081868!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a49e7cf48051d2f%3A0xb2f628924ed01192!2sRajInfoSolutions!5e0!3m2!1sen!2sin!4v1680869360186!5m2!1sen!2sin"
                     loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{ border: "none", borderRadius:"20px", width: "100%", height: "100%" }}></iframe>

                </div>
                <div className='col-md-3'>
                    <div className='fw-bold text-center mb-3 fs-3'>FOLLOW US</div>
                    <div className='fs-5 text-center'><i class="bi bi-facebook"></i> Facebook</div>
                    <div className='fs-5 text-center'><i class="bi bi-instagram"></i> Instagram</div>
                    <div className='fs-5 text-center'><i class="bi bi-twitter"></i> Twitter</div>
                </div>
            </div>
        </footer>

        <div class="text-center text-white" style={bottomStyle}>
            Copyright © 2023 Raj Info Solutions. All Rights Reserved.
        </div>
    </>
    )
}