export default function Carousel(){
    //const images = ['/bgs/1.jpg', '/bgs/2.jpg', '/bgs/3.jpg', '/bgs/4.jpg', '/bgs/5.jpg', '/bgs/6.jpg']
    return <>
        <div id="imageGalleryCarousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner" style={{backgroundColor:"#111"}}>
            <div class="carousel-item active py-1 px-3">
                <img src="/bgs/1.jpg" class="d-block w-100" alt="..." style={{borderRadius:"30px", border:"2px solid white"}} />
            </div>
            <div class="carousel-item py-1 px-3">
                <img src="/bgs/2.jpg" class="d-block w-100" alt="..." style={{borderRadius:"30px", border:"2px solid white"}} />
            </div>
            <div class="carousel-item py-1 px-3">
                <img src="/bgs/3.jpg" class="d-block w-100" alt="..." style={{borderRadius:"30px", border:"2px solid white"}} />
            </div>
            <div class="carousel-item py-1 px-3">
                <img src="/bgs/4.jpg" class="d-block w-100" alt="..." style={{borderRadius:"30px", border:"2px solid white"}} />
            </div>
            <div class="carousel-item py-1 px-3">
                <img src="/bgs/5.jpg" class="d-block w-100" alt="..." style={{borderRadius:"30px", border:"2px solid white"}} />
            </div>
            <div class="carousel-item py-1 px-3">
                <img src="/bgs/6.jpg" class="d-block w-100" alt="..." style={{borderRadius:"30px", border:"2px solid white"}} />
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#imageGalleryCarousel" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#imageGalleryCarousel" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
        </div>
    </>
}