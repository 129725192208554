import PotraitCard from "../components/PotraitCard"

export default function AboutUs(){
    const bgStyle = {
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundImage:  "linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/bgs/blur_bg_1.jpg')",
        color: "white"
    }
    return <>
        <div className="container-fluid p-5" style={bgStyle}>
            <p className="text-center fs-3">About Us & Our Team</p>

            <div className="row">
                <div className="d-none d-md-block col-md-1 col-lg-1"></div>
                <div className="col-sm-12 col-md-5 col-lg-5 text-center p-3">
                    <img src="/logos/tt_logo_title.jpg" alt="tt logo" className="d-block w-100" />
                    <p className="mt-3 small">Formerly "Techno Towers Computer Training and Services"
                    has been providing its full potential since 2009 with a 
                    vision to provide Training and Services to all students 
                    who approached us to build their career and our customers.</p>
                </div>
                <div className="col-sm-12 col-md-5 col-lg-5 text-center p-3">
                    <img src="/logos/ris_logo_title.png" alt="ris logo" className="d-block w-100" />
                    <p className="mt-3 small">We now expanding our system towards IT industry taking 
                    small steps towards growth and We the members of  "Raj Info Solutions"
                    start everyday with Strong Determination and make ourselves better and better.</p>
                </div>
                <div className="d-none d-md-block col-md-1 col-lg-1"></div>
            </div>

            <div className="px-5">
                <p className="text-center small"><strong>
                    Ours is a small elite team that always gets the job done  right and in a right way that keeps us thriving.
                    Currently we expertise in Trainings on Computer Courses, Digital Makrting and Promotions, 
                    Web hostings and Cloud Deployments, Career Guidance and Placement Assistance and Tech Support Services.
                </strong></p>
            </div>
            
            <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-4 text-center p-3">
                    <PotraitCard src="/team/rajesh.png" name="Olli Rajesh (M.Sc)" designation="Managing Director & Tutor"
                     bg="--bs-purple" color="--bs-white"/>
                </div>
                <div className="col-6 col-sm-6 col-md-4 col-lg-4 text-center p-3">
                    <div className="px-lg-4 px-md-2 py-3"> 
                        <PotraitCard src="/team/poorna.png" name="Poorna VSL (M.C.A)" designation="Developer & Tutor"
                        bg="--bs-success" color="--bs-white"/>
                    </div>
                </div>
                <div className="col-6 col-sm-6 col-md-4 col-lg-4 text-center p-3">
                    <div className="px-lg-4 px-md-2 py-3">
                        <PotraitCard src="/team/madhu.png" name="Madhu Bhushan Ch (M.Tech)" designation="Developer & Tutor"
                        bg="--bs-success" color="--bs-white"/>
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-around flex-wrap">
                <div className="p-2" style={{maxWidth:"220px"}}>
                    <PotraitCard src="/team/chakri.png" name="Chakravarthy" designation="Supporting Member"
                        bg="--bs-gray-dark" color="--bs-white"/>
                </div>
                <div className="p-2" style={{maxWidth:"220px"}}>
                    <PotraitCard src="/team/likitha.png" name="Likitha" designation="Supporting Member"
                        bg="--bs-blue" color="--bs-white"/>
                </div>
                <div className="p-2" style={{maxWidth:"220px"}}>
                    <PotraitCard src="/team/nikith.png" name="Nikith" designation="Supporting Member"
                        bg="--bs-pink" color="--bs-white"/>
                </div>
                <div className="p-2" style={{maxWidth:"220px"}}>
                    <PotraitCard src="/team/prasanna.png" name="Prasanna" designation="Supporting Member"
                        bg="--bs-red" color="--bs-white"/>
                </div>
                <div className="p-2" style={{maxWidth:"220px"}}>
                    <PotraitCard src="/team/tejesh.png" name="Tejesh" designation="Supporting Member"
                        bg="--bs-orange" color="--bs-white"/>
                </div>
            </div>

            <div className=" card bg-light px-4 py-2 mt-5">
                <p className="text-center" style={{fontStyle: "italic"}}>
                    <i class="bi bi-quote fs-1"></i>
                    Successful Organization Requires An Intellectual Team.</p>
            </div>
        </div>
    </>
}