import './Stats.css'
import './customStyles.css'
export default function Stats(){
    return (
    <>
        <div class="container-fluid" >
            <div class="row text-center bg-anim px-2 py-4">
                <div class="col-lg-3 col-md-6 col-sm-6 p-3 hover-zoom">
                    <h1><strong>6500+</strong></h1>
                    <p>Students Trained</p>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 p-3  hover-zoom ">
                    <h1><strong>10+</strong></h1>
                    <p>Global Meets per Day</p>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 p-3  hover-zoom ">
                    <h1><strong>89+</strong></h1>
                    <p>Happy Clients</p>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 p-3  hover-zoom ">
                    <h1><strong>150+</strong></h1>
                    <p>Projects Done</p>
                </div>
            </div>
        </div>
    </>)
}